import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import getAjax from "@/utils/request";
import Footer from '@/components/Footer'
import '@/assets/less/style.less'
import '@/assets/less/chat.less'
import '@/utils/defaultFunc.js'


Vue.prototype.netCall = getAjax

Vue.component('Footer',Footer);

new Vue({
  router,
  Vuex,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  $('body').off();
  document.title = to.meta.title || '首页'
  next();
})