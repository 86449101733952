import Vue from "vue";
function uploadFile(callback, multiple = false) {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = multiple;
    input.style.display = 'none';
    input.addEventListener('change', (event) => {
        const selectedFile = multiple ? event.target.files : event.target.files[0];
        if (selectedFile && callback) {
            callback(selectedFile);
        }
    });
    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
}

function fileAddHost(path) {
    var str = path + '';
    if (str == 'null') return '';
    if (str.toLowerCase().indexOf('http') !== 0) {
        return httpHost + str;
    }
    return str;
}

function goPage(path){
    window.location.href = path;
}

function loading() {
    const uploadLayer = layer.open({
        type: 1,
        content: '<div class="uploading-modal">Uploading...</div>',
        shadeClose: false,
        success: (layero, index) => {
            setTimeout(() => {
                layer.close(index);
            }, 2000);
        }
    });
}

/** 退出 */
function logout() {
    localStorage.clear();
    window.location.href = '/';
}

Vue.prototype.uploadFile = uploadFile;
Vue.prototype.fileAddHost = fileAddHost;
Vue.prototype.logout = logout;
Vue.prototype.loading = loading;
Vue.prototype.goPage = goPage;