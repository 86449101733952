import axios from "axios";
const getAjax = async (url, requestParams, sfun, isFormData) => {
    if (isFormData) {
        var formData = requestParams;
    } else {
        var formData = new FormData();
        Object.keys(requestParams).forEach(e => {
            formData.append(e, requestParams[e]);
        })
    }

    if(url !== '/Appapi/Login/login.html'){
        formData.append("token",localStorage.getItem("token"));
    }
    if(url == '/Appapi/Index/headUpload'){//上传接口
        $('.df_mark').show();
    }

    axios({
        method: 'post',
        url: '/api' + url,
        // `headers`是要发送的自定义请求头
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData,
    }).then((res) => {
        $('.df_mark').hide();
        if (res.data.status == -9999) {
            window.location.href='/#/login';
            return;
        }
        if (res.data.status != 1 && res.data.status != 200 && res.data.code != 200) {
            layer.msg(res.data.msg);
        }
        sfun(res.data);
    });
};
export default getAjax;