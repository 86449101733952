var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-tabbar"},[_c('a',{staticClass:"weui-tabbar__item",attrs:{"href":"/#/info/index"}},[_c('span',{staticStyle:{"display":"inline-block","position":"relative"}},[_c('img',{staticClass:"weui-tabbar__icon",attrs:{"src":_vm.checked == 0
            ? '/assets/images/tabbar_1_hover.png'
            : '/assets/images/tabbar_1.png',"alt":"信息"}}),_c('span',{staticClass:"weui-badge newChatNum",staticStyle:{"position":"absolute","top":"-2px","right":"-13px","display":"none"}})]),_c('p',{staticClass:"weui-tabbar__label"},[_vm._v("信息")])]),_c('a',{staticClass:"weui-tabbar__item",attrs:{"href":"/#/contact/index"}},[_c('span',{staticStyle:{"display":"inline-block","position":"relative"}},[_c('img',{staticClass:"weui-tabbar__icon",attrs:{"src":_vm.checked == 1
            ? '/assets/images/tabbar_2_hover.png'
            : '/assets/images/tabbar_2.png',"alt":"通讯录"}}),_c('span',{staticClass:"weui-badge",staticStyle:{"position":"absolute","top":"-2px","right":"-13px","display":"none"},attrs:{"id":"newFriendship"}})]),_c('p',{staticClass:"weui-tabbar__label"},[_vm._v("通讯录")])]),_c('a',{staticClass:"weui-tabbar__item",attrs:{"href":"/#/zhuanqian/index"}},[_c('img',{staticClass:"weui-tabbar__icon",attrs:{"src":_vm.checked == 2
          ? '/assets/images/tabbar_4_hover.png'
          : '/assets/images/tabbar_4.png',"alt":"发现"}}),_c('p',{staticClass:"weui-tabbar__label"},[_vm._v("发现")])]),_c('a',{staticClass:"weui-tabbar__item",attrs:{"href":"/#/user/index"}},[_c('span',{staticStyle:{"display":"inline-block","position":"relative"}},[_c('img',{staticClass:"weui-tabbar__icon",attrs:{"src":_vm.checked == 3
            ? '/assets/images/tabbar_3_hover.png'
            : '/assets/images/tabbar_3.png',"alt":"我的"}})]),_c('p',{staticClass:"weui-tabbar__label"},[_vm._v("我的")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }