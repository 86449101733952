<template>
  <div class="weui-tabbar">
    <a href="/#/info/index" class="weui-tabbar__item">
      <span style="display: inline-block; position: relative">
        <img
          :src="
            checked == 0
              ? '/assets/images/tabbar_1_hover.png'
              : '/assets/images/tabbar_1.png'
          "
          alt="信息"
          class="weui-tabbar__icon"
        />
        <span
          class="weui-badge newChatNum"
          style="position: absolute; top: -2px; right: -13px; display: none"
        ></span>
      </span>
      <p class="weui-tabbar__label">信息</p>
    </a>
    <a href="/#/contact/index" class="weui-tabbar__item">
      <span style="display: inline-block; position: relative">
        <img
          :src="
            checked == 1
              ? '/assets/images/tabbar_2_hover.png'
              : '/assets/images/tabbar_2.png'
          "
          alt="通讯录"
          class="weui-tabbar__icon"
        />
        <span
          class="weui-badge"
          id="newFriendship"
          style="position: absolute; top: -2px; right: -13px; display: none"
        ></span>
      </span>
      <p class="weui-tabbar__label">通讯录</p>
    </a>
    <a href="/#/zhuanqian/index" class="weui-tabbar__item">
      <img
        :src="
          checked == 2
            ? '/assets/images/tabbar_4_hover.png'
            : '/assets/images/tabbar_4.png'
        "
        alt="发现"
        class="weui-tabbar__icon"
      />
      <p class="weui-tabbar__label">发现</p>
    </a>
    <a href="/#/user/index" class="weui-tabbar__item">
      <span style="display: inline-block; position: relative">
        <img
          :src="
            checked == 3
              ? '/assets/images/tabbar_3_hover.png'
              : '/assets/images/tabbar_3.png'
          "
          alt="我的"
          class="weui-tabbar__icon"
        />
      </span>
      <p class="weui-tabbar__label">我的</p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: String,
      defatult: "0",
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="less">
</style>
