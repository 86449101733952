<template>
  <div id="app">
    <router-view />
    <audio src="/assets/tip.mp3" id="audioTip" autoplay="false"></audio>
  </div>
</template>
<script>
export default {
  mounted() {
    
  },
};
</script>